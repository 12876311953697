/* Algemene instellingen */
.container { max-width:1080px; }
input, textarea {
    background: #fff none repeat scroll 0 0;
    border: 1px solid rgb(193, 194, 196);
    color: #404041;
    margin: 0 0 30px;
    padding: 5px 20px;
    width: 100%;
}

.arealis_latest .arealis_btn a {
    padding: 10px 30px;
}
.arealis_btn {
    background: #ffcc33 none repeat scroll 0 0;
    display: inline-block;
    padding: 5px 15px;
    border: 1px solid #f3e731;
}

.animated { animation-duration : 2s;}

a[href^="tel"]{
    color:inherit;
    text-decoration:none;
}

/* Algemene Fonts */
body, button, input, select, textarea { color:#233836; font-family: "DIN-Lighter", Helvetica, sans-serif; line-height:1.6; font-size: 2.4rem; font-weight:normal;}
a {    color: #233836;    text-decoration: none; }
p { font-size:2rem;}

/* Header & Navigatie */
.navbar { margin-bottom: 0px; border-radius:0;}

.navbar-arealis { 
	background: url('../images/bg-sand.jpg') 0px 60px; 
	border:0px; 
	color:#fff;
	text-align:center;
}

.navbar-arealis .navbar-nav {
    display: inline-block;
    float: none;
}

.navbar-arealis .navbar-collapse {
    text-align: center;
}

.navbar-arealis .navbar-nav > li > a { color:#fff; padding-right: 70px; top:5px;}
.navbar-arealis .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus { background:none; color:#1D5F5B;}

header { 
    background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(246, 246, 246, 0.25) 25%, rgba(240, 240, 240, 1) 100%) repeat scroll 0 0;
    margin-bottom:100px;
    margin-top:100px;
}

.logo-top { padding-top:20px; }
.taxateurs-logo-top { padding-left: 32px;}

.subtitle h1 {
    font-size: 5.6rem;
    font-weight: lighter;
    margin: 0;
    text-transform: uppercase;
    color:#1D5F5B;
    padding-top:52px;
    line-height:1.1;
}

.subtitle .bold {
   	font-family: "DIN-normal",Helvetica,sans-serif;
    font-weight: normal;
    font-size: 5.6rem;
    font-weight: lighter;
    margin: 0;
    text-transform: uppercase;
    color:#1D5F5B;
    padding-left:50px;
    line-height:1.1;
}

.meer-weten, .btn {
	display:block;
	clear:both;
	width:100%;
	text-align:center;
}
.btn {
	text-align:left;
}
.meer-weten a, .btn a {
	display:inline-block;
	position:relative;
	padding: 5px 10px;
	border:1px solid #77787b;
	text-transform: uppercase;
	font-size:20px;
	font-size:2rem;
	background:#ffffff;
}
.meer-weten a:before,
.meer-weten a:after,
.btn a:before,
.btn a:after {
	content:'';
	display:block;
	width: 0;
	height: 0;
	position:absolute;
	top:100%;
	left:45%;
	border: 7px solid transparent;
	-webkit-transition: all 150ms linear;
	-moz-transition: all 150ms linear;
	-o-transition: all 150ms linear;
	transition: all 150ms linear;
}
.meer-weten a:before {
	margin-left:-1px;
	border-top-color: #77787b;
}
.meer-weten a:after {
	border-top-color: #fff;
	margin-top:-1px;
	margin-left:-1px;
}
.meer-weten.big a:before,
.meer-weten.big a:after,
.meer-weten a:hover:before,
.meer-weten a:hover:after {
	border-width:16px;
	margin-left:-10px;
}

.meer-weten a { color: #77787b; text-decoration:none;}

.waarom-arealis h2 {     
	font-size: 4.5rem;
    line-height: 1.11111;
    margin-bottom: 1em;
    margin-top:  2em;
    text-transform:uppercase;
}

.waarom-arealis { margin-bottom: 1em; }
.waarom-arealis img { position:relative; top:-20px;}

/* Arealis content */
#arealis .row  { margin-bottom : 120px; }
#arealis .row.arealis-animatie-1 { margin-bottom:0;}
#arealis .scetch { position:relative; top:-240px;}
#arealis .first { position:relative; top:-240px; }
#arealis h2 {     font-size: 4.5rem;
    line-height: 1.11111;
    margin-bottom: 0.9231em;
    margin-top: 0; 
    text-transform: uppercase}
    
#arealis li::before {
    background: transparent url("../images/bullist.png") no-repeat scroll 0 0;
    content: "";
    display: block;
    height: 16px;
    left: -40px;
    position: absolute;
    top: 8px;
    width: 16px;
}

#arealis li {
   list-style: outside none none;
    margin: 0 0 15px;
    padding: 0;
    position: relative;
}
 
.arrow {
    background: transparent url("../images/sprite.png") no-repeat scroll 0 0;
    display: block;
    height: 25px;
    margin-left: 75%;
    margin-top: -10%;
    width: 30px;
}

.arrow.arrow-5.up.animated.fadeInLeft { position:relative; top:20px;}

#arealis .arrow.straight {
    background-position: 0 -21px;
    margin-top: -14%;
}


#arealis .arrow.down {
    background-position: 0 -47px;
    margin-left: 80%;
}

#frank { background: url('../images/bg-sand.jpg'); margin-bottom:100px; padding-bottom:100px; }

#frank h2 {     
	font-size: 4.5rem;
    line-height: 1.11111;
    margin-bottom: 1em;
    margin-top:  1em;
    text-transform:uppercase;
}

#frank .arrow.down {
    background-position: 0 -47px;
    margin-left: 80%;
}

#frank h3 { color:#fff; font-family: "DIN-Lighter", Helvetica, sans-serif; line-height:1.6; font-size: 2.4rem; font-weight:normal; padding-top:74px;}

#contact { margin-bottom:200px; }

#contact h2 {     font-size: 4.5rem;
    line-height: 1.11111;
    margin-bottom: 0.9231em;
    margin-top: 0; 
    text-transform: uppercase}
    
.talking {
    margin-top: 68px;
    position: relative;
}
.talking::after {
    background: transparent url("../images/talking.png") no-repeat scroll center bottom;
    content: "";
    display: block;
    height: 100px;
    left: 10%;
    position: absolute;
    top: -94px;
    width: 135px;
}

footer { padding-top:30px; padding-bottom:100px; background-color: #1D5F5A; color:#fff; font-size:2.0rem; }
footer .footer-logo { position: relative; top: 5px}
footer .footer-huis { position: relative; top: -188px}
footer .smalltekst { position: relative; top: 21px; font-size:1.5rem;} 
footer .arealis_btn { font-size:2.0rem;  padding: 10px 15px;}
footer .footer-line { line-height: 36px;}

/* Responsive: Portrait tablets and up */
@media screen and (max-width: 992px) {
 .subtitle h1 {font-size:3.6rem;}
 .subtitle .bold {font-size:3.6rem; margin-bottom:20px;}
 .no-mobile { display:none;}
 .waarom-arealis img {    top: -10px;    width: 50px; }
  #arealis .row { padding-bottom:00px;}
  #arealis .scetch {    position: relative;    top: -173px;    width: 100%;}
  #arealis h2 { letter-spacing: -1px; }
  #frank {  padding-top: 30px; }
  #frank h2 { font-size: 3.5rem; }
  #frank h3 {    padding-top: 51px; }
  #arealis .first { top:-173px;}
  /*.arrow { display:none;}*/
  #contact { padding:0; }
  footer { font-size:17px; }
  footer  .footer-huis-module { text-align:center;  }
  footer .footer-huis { width:100%;}
  footer .arealis_btn { font-size:12px; }
  footer .smalltekst { position: relative; top: -8px; font-size:12px;} 
  footer .footer-huis {    position: relative;    top: -117px;    width: 87%; }
  footer .footer-line { line-height: inherit;}
}

/* Responsive: Portrait tablets and up */
@media screen and (max-width: 768px) {
 body { font-sieze:2rem; }
 .arrow { display:none;}
 .subtitle {    left: -20px;    position: relative;}
 .subtitle h1 {font-size:2.2rem; padding-top:16px;}
 .subtitle .bold {font-size:2.2rem; padding-left:0; margin-bottom:20px;}
 .taxateurs-logo-top { padding:0;}
 .meer-weten, .btn{  padding-top: 25px; }
 .waarom-arealis h2 { font-size: 3.4rem; margin-bottom:0;}
 .waarom-arealis img { top:0;}
 #arealis .scetch { width:auto; padding-top: 0; top:0;}
 #arealis .first { top:0}
 #frank h2 { margin:0;}
 #frank h3 { padding:0;}
 .arealis_btn > a { font-size:16px;}
  footer { font-size:16px; }
 footer .footer-huis { top:0; margin:20px;}
}